var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-wrapper"},[_c('div',{staticClass:"signup-content"},[_c('h1',{staticClass:"signup-main-title"},[_vm._v(" "+_vm._s(_vm.$t("signup.dashboard.title", { name: _vm.customerName }))+" ")]),(_vm.signupCompleted)?_c('div',{staticClass:"signup-notice-bars"},[_c('CompassNoticeBar'),_c('CompassPendingAccountData')],1):_vm._e(),(_vm.hasTransactions || _vm.displayRecentInvestmentAlert)?_c('div',{staticClass:"invested-customer-content"},[_c('div',{staticClass:"signup-customer-transactions"},[_c('Transactions')],1),_c('ClickableOptionCard',{staticClass:"completed-step",attrs:{"id":"new-investment-card","leftIcon":"mdi-plus-circle-outline","rightIcon":"mdi-chevron-right","title":_vm.$t('signup.dashboard.investedCustomer.newInvestment')},on:{"optionClicked":function($event){return _vm.$router.push({ name: 'investViewFunds' })}}})],1):_c('div',[_c('p',{staticClass:"signup-instruction"},[_vm._v(" "+_vm._s(_vm.$t( `signup.dashboard.instructions.${ _vm.signupCompleted ? "signedUp" : "notSignedUp" }`, ))+" ")]),_c('div',{staticClass:"signup-menu-options"},_vm._l((_vm.menuOptions),function(option,index){return _c('div',{key:index,staticClass:"signup-menu-option"},[_c('ClickableOptionCard',{class:{
              'non-clickable':
                (option.completed && option.key !== 'invest')
                || _vm.isDisabled(option)
                || (_vm.signupCompleted && option.key !== 'invest'),
              'completed-step': option.completed || _vm.signupCompleted,
              'on-hold-step': option.onHold,
              'rejected-step': option.rejectedByGesintel,
              'intermediate-step': option.partial,
            },attrs:{"id":option.key,"leftIcon":option.leftIcon,"rightIcon":_vm.getRightIcon(option),"title":_vm.$t(`signup.dashboard.steps.${option.key}.title`),"disabled":_vm.isDisabled(option) && !_vm.signupCompleted,"error":option.error,"subtitle":_vm.getOptionSubtitle(option)},on:{"optionClicked":function($event){return _vm.handleOption(option)}}})],1)}),0)]),_c('div',{staticClass:"more-content"},[(!_vm.signupCompleted)?_c('div',[_c('h3',{staticClass:"signup-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("signup.dashboard.moreContent.viewFunds.label"))+" ")]),_c('ClickableOptionCard',{attrs:{"id":"invest-view-funds-card","title":_vm.$t('signup.dashboard.moreContent.viewFunds.menuTitle'),"leftIcon":"mdi-view-grid-outline"},on:{"optionClicked":function($event){return _vm.$router.push({ name: 'investViewFunds' })}}})],1):_vm._e(),_c('h3',{staticClass:"signup-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("signup.dashboard.moreContent.chat.label"))+" ")]),_c('ClickableOptionCard',{attrs:{"title":_vm.$t('signup.dashboard.moreContent.chat.menuTitle'),"leftIcon":"mdi-message-processing-outline"},on:{"optionClicked":function($event){return _vm.openChat()}}})],1)]),_c('HorizontalButtonDialog',{attrs:{"id":"rejected-customer-dialog","value":_vm.displayRejectedCustomerDialog,"title":_vm.$t('signup.dashboard.rejectedCustomerDialog.title'),"text":_vm.$t('signup.dashboard.rejectedCustomerDialog.text'),"primaryButtonText":_vm.$t('base.whatsApp'),"secondaryButtonText":_vm.$t('base.helpCenter'),"primaryButtonIcon":"mdi-whatsapp"},on:{"display":function($event){_vm.displayRejectedCustomerDialog = $event},"primaryClick":function($event){return _vm.redirectsToWhatsApp()},"secondaryClick":function($event){return _vm.redirectsToHelpCenter()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }