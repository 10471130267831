<template>
  <tbody
    :class="[isMobileContainer ? 'mobile-table' : 'desktop-table']"
    id="pending-movements-table"
  >
    <tr v-for="pendingMovement in pendingMovements" v-bind:key="pendingMovement.id">
      <template v-if="isMobileContainer">
        <td class="pl-5">
          <div class="flex-col-items">
            <div class="flex-icons">
              <div
                class="icon-circle center-in-page"
                :style="`background-color: ${$options.filters.addFundColor(
                  getPendingMovementSourceFund(pendingMovement),
                )}`"
              >
                <v-icon size="10" color="#fff">
                  {{ getFundIcon(pendingMovement) }}
                </v-icon>
              </div>
              <span>
                {{ getPendingMovementSourceFund(pendingMovement) | fundShortName }}
              </span>
            </div>

            <div class="flex-icons" :class="{ 'error-state': hasRejectedStatus(pendingMovement) }">
              <v-icon size="17" color="#333">
                {{
                  getMovementDirection(pendingMovement.movementType) === "in"
                    ? "mdi-plus-circle-outline"
                    : " mdi-arrow-top-right-thin-circle-outline"
                }}
              </v-icon>
              <div>
                $ {{ pendingMovement.shareState.CLP | currencyNoSymbol }}
              </div>
            </div>
          </div>
        </td>

        <td :class="{ 'error-state': hasRejectedStatus(pendingMovement) }">
          <p class="pending-movement-status">
            {{
              $t(
                `transactions.pendingMovements.${
                  pendingMovement.movementType
                }.${getTransactionStatus(pendingMovement)}`,

                {
                  date: $options.filters.getMonthAndDay(pendingMovement.settlementDate),
                  accountNumber: clpBankAccount ? clpBankAccount.accountNumber : "",
                },
              )
            }}
          </p>
        </td>
      </template>
      <template v-else>
        <td>
          <div class="flex-icons movement-type">
            <v-icon size="17" color="#333333">
              {{
                getMovementDirection(pendingMovement.movementType) === "in"
                  ? "mdi-plus-circle-outline"
                  : " mdi-arrow-top-right-thin-circle-outline"
              }}
            </v-icon>
            <span>
              {{ $t(`transaction.type.${pendingMovement.movementType}`) }}
            </span>
          </div>
        </td>
        <td>
          <div class="flex-icons">
            <div
              class="icon-circle center-in-page"
              :style="`background-color: ${$options.filters.addFundColor(
                getPendingMovementSourceFund(pendingMovement),
              )}`"
            >
              <v-icon size="14" color="#fff">
                {{ getFundIcon(pendingMovement) }}
              </v-icon>
            </div>
            <span>
              {{ getPendingMovementSourceFund(pendingMovement) | fundShortName }}
            </span>
          </div>
        </td>
        <td>
          <div class="pending-movement-status" :class="{ 'error-state': hasRejectedStatus(pendingMovement) }">
            <span>
              {{
                $t(
                  `transactions.pendingMovements.${
                    pendingMovement.movementType
                  }.${getTransactionStatus(pendingMovement)}`,

                  {
                    date: $options.filters.getMonthAndDay(pendingMovement.settlementDate),
                    accountNumber: clpBankAccount ? clpBankAccount.accountNumber : "",
                  },
                )
              }}
            </span>
          </div>
        </td>
        <td :class="{ 'error-state': hasRejectedStatus(pendingMovement) }">
          <div>${{ pendingMovement.shareState.CLP | currencyNoSymbol }}</div>
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from "vuex";
import containerSize from "@/mixins/containerSize.js";
import movements from "@/mixins/movements.js";
import funds from "@/mixins/funds.js";

export default {
  name: "PendingMovements",

  data() {
    return {
      // Needed for containerSize mixin
      containerId: "pending-movements-table",
    };
  },

  mixins: [movements, funds, containerSize],

  computed: {
    ...mapGetters(["loadingPendingMovements", "pendingMovements", "clpBankAccount"]),
  },

  methods: {
    getTransactionStatus(pendingMovement) {
      const statusTrace = [
        { step: 0, status: "inProcess" },
        { step: 1, status: "ending" },
        { step: "REJECTED", status: "error" },
      ];

      const step = this.getStep(pendingMovement);
      return statusTrace.find(status => status.step === step)?.status;
    },

    getStep(pendingMovement) {
      const currentDate = this.$dayjs().format("YYYY-MM-DD");

      if (this.hasRejectedStatus(pendingMovement)) {
        return "REJECTED";
      }

      const settlementDate = this.$dayjs(pendingMovement.settlementDate).format("YYYY-MM-DD");
      return settlementDate === currentDate ? 1 : 0;
    },

    hasRejectedStatus(pendingMovement) {
      return pendingMovement?.externalIntegrationStatus === "REJECTED";
    },

    getFundIcon(pendingMovement) {
      return `mdi-${this.getFundStaticData(this.getPendingMovementSourceFund(pendingMovement))?.icon}`;
    },

    getPendingMovementSourceFund(pendingMovement) {
      const sourceFund = pendingMovement?.destinationFundSeries || pendingMovement?.originFundSeries;
      return sourceFund.fund.run;
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr td:last-child {
  text-align: right;
}

tbody tr:last-child {
  border-bottom: 1px solid #e2e0e0;
}

tbody tr td span,
div {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333 !important;
}

.flex-col-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  white-space: nowrap;
  gap: 0.5rem;
}

.movement-type {
  margin-right: 5px;
}

p {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  margin: 0 !important;
  text-align: left;
}
.desktop-table:not(:last-child):before {
  content: "@";
  display: block;
  line-height: 12px;
  text-indent: -99999px;
}

.error-state >*{
  color: #F20E45 !important;
}

.desktop-table {
  tr {
    height: 60px !important;
    border-top: 1px solid #e2e0e0;
  }

  .icon-circle {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .flex-icons {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0 10px;
  }
}

.mobile-table:not(:last-child):before {
  content: "@";
  display: block;
  text-indent: -99999px;
  line-height: 0px;
}

.mobile-table {
  tr {
    height: 80px;
    border-top: 1px solid #e2e0e0;
  }
  tr td {
    width: 50%;
    height: 60px;
  }
  .icon-circle {
    border-radius: 50%;
    width: 15px;
    min-width: 15px;
    height: 15px;
  }

  .flex-icons {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;
  }

  .pending-movement-status {
    padding-right: 32px;
  }
}
</style>
