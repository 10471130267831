<template>
  <div class="welcome">
    <div class="welcome-wrapper">
      <div
        class="welcome-title"
        v-html="$i18n.t('home.welcome.title', { name: greetings })"
      />
      <div
        class="last-update"
        v-html="$i18n.t('home.welcome.updatedAt', { weekday: closingDay })"
        v-if="closeDate && investedCustomer"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WelcomeSection",

  computed: {
    ...mapGetters(["closeDate", "investedCustomer", "isJuridicPerson", "profile"]),

    closingDay() {
      return this.$options.filters.getClosingDay(this.closeDate);
    },

    customerFirstName() {
      return this.$options.filters.customerName(localStorage.getItem("userName") || "");
    },

    greetings() {
      if (this.isJuridicPerson) return `${this.profile?.name} 👋🏽`;
      if (!this.investedCustomer) return `${this.customerFirstName} 👋🏽`;
      return this.customerFirstName;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
  margin: 0 10px;
  @media (max-width: 960px) {
    margin: 0 20px;
  }
}
.welcome-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
  color: #333333;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    color: #333333;
  }
}

.last-update {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #646363;
  @media (max-width: 960px) {
    font-size: 10px;
  }
  @media (max-width: 370px) {
    font-size: 8px;
  }
}
</style>
