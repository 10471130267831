<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <h1 class="signup-main-title">
        {{ $t("signup.dashboard.title", { name: customerName }) }}
      </h1>

      <div class="signup-notice-bars" v-if="signupCompleted">
        <CompassNoticeBar />
        <CompassPendingAccountData />
      </div>

      <div
        v-if="hasTransactions || displayRecentInvestmentAlert"
        class="invested-customer-content"
      >
        <div class="signup-customer-transactions">
          <Transactions />
        </div>
        <ClickableOptionCard
          id="new-investment-card"
          class="completed-step"
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-chevron-right"
          :title="$t('signup.dashboard.investedCustomer.newInvestment')"
          @optionClicked="$router.push({ name: 'investViewFunds' })"
        />
      </div>

      <div v-else>
        <p class="signup-instruction">
          {{
            $t(
              `signup.dashboard.instructions.${
                signupCompleted ? "signedUp" : "notSignedUp"
              }`,
            )
          }}
        </p>
        <div class="signup-menu-options">
          <div
            class="signup-menu-option"
            v-for="(option, index) in menuOptions"
            :key="index"
          >
            <ClickableOptionCard
              :id="option.key"
              :class="{
                'non-clickable':
                  (option.completed && option.key !== 'invest')
                  || isDisabled(option)
                  || (signupCompleted && option.key !== 'invest'),
                'completed-step': option.completed || signupCompleted,
                'on-hold-step': option.onHold,
                'rejected-step': option.rejectedByGesintel,
                'intermediate-step': option.partial,
              }"
              :leftIcon="option.leftIcon"
              :rightIcon="getRightIcon(option)"
              :title="$t(`signup.dashboard.steps.${option.key}.title`)"
              :disabled="isDisabled(option) && !signupCompleted"
              :error="option.error"
              :subtitle="getOptionSubtitle(option)"
              @optionClicked="handleOption(option)"
            />
          </div>
        </div>
      </div>

      <div class="more-content">
        <div v-if="!signupCompleted">
          <h3 class="signup-subtitle">
            {{ $t("signup.dashboard.moreContent.viewFunds.label") }}
          </h3>
          <ClickableOptionCard
            id="invest-view-funds-card"
            :title="$t('signup.dashboard.moreContent.viewFunds.menuTitle')"
            leftIcon="mdi-view-grid-outline"
            @optionClicked="$router.push({ name: 'investViewFunds' })"
          />
        </div>
        <h3 class="signup-subtitle">
          {{ $t("signup.dashboard.moreContent.chat.label") }}
        </h3>
        <ClickableOptionCard
          :title="$t('signup.dashboard.moreContent.chat.menuTitle')"
          leftIcon="mdi-message-processing-outline"
          @optionClicked="openChat()"
        />
      </div>
    </div>

    <HorizontalButtonDialog
      id="rejected-customer-dialog"
      :value="displayRejectedCustomerDialog"
      :title="$t('signup.dashboard.rejectedCustomerDialog.title')"
      :text="$t('signup.dashboard.rejectedCustomerDialog.text')"
      :primaryButtonText="$t('base.whatsApp')"
      :secondaryButtonText="$t('base.helpCenter')"
      primaryButtonIcon="mdi-whatsapp"
      @display="displayRejectedCustomerDialog = $event"
      @primaryClick="redirectsToWhatsApp()"
      @secondaryClick="redirectsToHelpCenter()"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ClickableOptionCard from "@/components/shared/ClickableOptionCard.vue";
import funds from "@/mixins/funds.js";
import Transactions from "@/components/home/Transactions.vue";
import CompassPendingAccountData from "@/components/home/CompassPendingAccountData.vue";
import CompassNoticeBar from "@/components/home/CompassNoticeBar.vue";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";

export default {
  name: "SignupDashboard",

  data() {
    return {
      displayRejectedCustomerDialog: false,
    };
  },

  components: {
    ClickableOptionCard,
    Transactions,
    CompassPendingAccountData,
    CompassNoticeBar,
    HorizontalButtonDialog,
  },

  mixins: [funds, customersApiFunctions],

  computed: {
    ...mapState(["signup"]),
    ...mapGetters([
      "signupStatus",
      "authenticatedStatus",
      "hasTransactions",
      "displayRecentInvestmentAlert",
      "profile",
      "signupProfilingIdentificationError",
      "mutationErrors",
      "signupCompleted",
      "signupRut",
    ]),

    profilingIdentificationOnHold() {
      const {
        pendingProfilingIdentificationReview,
        acceptedProfilingIdentificationReview,
      } = this.signupStatus;

      // If there is an error in the profiling identification step, the step is on hold
      if (this.signupProfilingIdentificationError) {
        // List of status codes that put the profiling step on hold
        const onHoldStatusCodes = [
          "COMPASS_CLIENT_CREATION_ERROR",
          "COMPASS_REJECTION_DATA_EVALUATION",
          "COMPASS_REJECTION_NATIONALITY",
        ];

        return this.mutationErrors.some(error => onHoldStatusCodes.includes(error.code));
      }
      // If the profiling identification review is pending and not accepted, the step is on hold
      const pendingReview =
        pendingProfilingIdentificationReview && !acceptedProfilingIdentificationReview;

      return pendingReview;
    },

    rejectedByGesintel() {
      if (this.signupProfilingIdentificationError) {
        const gesintelStatusCodes = [
          "COMPASS_REJECTION_GESINTEL",
          "COMPASS_REJECTION_PEP",
        ];
        return this.mutationErrors.some(error => gesintelStatusCodes.includes(error.code));
      }
      return false;
    },

    menuOptions() {
      const { profilingIdentificationOnHold, rejectedByGesintel } = this;

      const {
        completedValidation,
        completedProfiling,
        completedIdentification,
        pendingProfilingIdentificationReview,
        acceptedProfilingIdentificationReview,
        completedContractCompass,
      } = this.signupStatus;

      const profilingIdentificationCompleted =
        (completedProfiling && completedIdentification) ||
        (pendingProfilingIdentificationReview && acceptedProfilingIdentificationReview);

      const options = [
        {
          key: "identification",
          leftIcon: "mdi-account-box-outline",
          completed: completedValidation,
          route: "signupIdentification",
        },
        {
          key: "profiling",
          leftIcon: "mdi-emoticon-outline",
          partial: completedIdentification && !completedProfiling,
          completed: profilingIdentificationCompleted,
          error:
            !profilingIdentificationOnHold && !profilingIdentificationCompleted ?
              this.signupProfilingIdentificationError :
              false,
          onHold: profilingIdentificationOnHold,
          rejectedByGesintel,
          route: completedIdentification ?
            "signupProfilingIntro" :
            "signupIdentificationPersonal",
        },
        {
          key: "contract",
          leftIcon: "mdi-clipboard-text-outline",
          completed: completedContractCompass,
          route: "signupContract",
        },
        {
          key: "invest",
          leftIcon: "mdi-plus-circle-outline",
          completed: false,
          route: "investViewFunds",
        },
      ];

      return options.map(option => ({
        ...option,
        partial: option?.partial || false,
        onHold: option?.onHold || false,
        rejectedByGesintel: option?.rejectedByGesintel || false,
        error: option?.error || false,
      }));
    },

    customerName() {
      const displayName = this.signup?.name || this.profile?.name;
      return this.$options.filters.customerName(displayName);
    },
  },

  methods: {
    isCompletedStep(menuOption) {
      return this.menuOptions.find(option => option.key === menuOption.key).completed;
    },

    handleOption(option) {
      if (option.completed || option.onHold) return;

      // If the user is rejected by GESINTEL, opens a modal with the contact options
      if (option.rejectedByGesintel) {
        this.displayRejectedCustomerDialog = true;
        return;
      }

      this.$router.push({ name: option.route });
    },

    isDisabled(menuOption) {
      if (menuOption.key === "invest") {
        return !this.signupCompleted;
      }
      if (menuOption.key === "profiling") {
        return !this.signupStatus.completedValidation && !this.signupCompleted;
      }
      if (menuOption.key === "contract") {
        return (
          !(
            this.signupStatus.completedValidation &&
            this.signupStatus.completedIdentification &&
            this.signupStatus.completedProfiling
          ) && !this.signupCompleted
        );
      }
      return false;
    },

    getRightIcon(menuOption) {
      // Not clickable option has no right icon
      if (this.isDisabled(menuOption)) return "";
      // If the option is on hold, the icon is a clock
      if (menuOption.onHold) return "mdi-clock-outline";
      // If there is an error, the icon is an alert circle
      if (menuOption?.error) return "mdi-alert-circle-outline";

      // Except for the invest option, when everything is finished the icon is a check mark
      if (
        (this.signupCompleted && menuOption.key !== "invest") ||
        this.isCompletedStep(menuOption)
      ) {
        return "mdi-check-circle-outline";
      }

      // An uncompleted option has the chevron icon
      return "mdi-chevron-right";
    },

    getOptionSubtitle(option) {
      let subtitleKey = "incompletedText";

      if (this.isDisabled(option)) return "";
      if (option?.error) subtitleKey = "errorText";
      else if (option.completed || this.signupCompleted) subtitleKey = "completedText";
      else if (option.partial) subtitleKey = "partialText";

      // On hold must have priority over the other states
      if (option.onHold) subtitleKey = "onHoldText";

      if (option.rejectedByGesintel) subtitleKey = "rejectedText";

      return this.$t(`signup.dashboard.steps.${option.key}.${subtitleKey}`);
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("signup.dashboard.moreContent.chat.intercom"),
      );
    },

    redirectsToWhatsApp() {
      const message = this.$t("signup.dashboard.rejectedCustomerDialog.whatsAppMessage", { rut: this.signupRut });
      window.open(`https://wa.me/+56982128541?text=${encodeURIComponent(message)}`, "_blank");
    },

    redirectsToHelpCenter() {
      window.open("https://ayuda.dvacapital.com/es/", "_blank");
    },

  },

  async mounted() {
    await this.getCustomerStatus();
  },
};
</script>

<style lang="scss">
.signup-view {
  display: flex;
  justify-content: center;
  margin: 60px 20px;

  @media (max-width: 960px) {
    margin: 40px 20px;
  }

  @media (max-width: 600px) {
    margin: 0 !important;
  }
}
.signup-subheader {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  margin-top: 20px;
}

.signup-subheader-highlight {
  font-weight: 700;
}

.signup-terms-url {
  text-decoration: none;
  color: #333333 !important;
}
.error-text {
  color: red !important;
}

.signup-terms-conditions {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #333333;
  margin-top: 40px;
}

.signup-input-field {
  border-radius: 10px;
}

.signup-step-back {
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

h1.signup-main-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #333333;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
}

h2.signup-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  color: #333333;
}

h3.signup-subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 20px;
}

.signup-wrapper {
  display: flex;
  justify-content: center;
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}

.signup-content {
  max-width: 420px;
  width: 100%;

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    /**Fill color for every input slot in signup pages */
    background: #ffffff !important;
    /** Overwriting vuetify input text properties */
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #333333;
  }
}

.signup-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }
}

.more-content > * {
  margin-bottom: 20px !important;
}

p.signup-instruction {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px !important;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.signup-menu-option + .signup-menu-option {
  margin-top: 10px;
}

.signup-menu-options .completed-step .right-icon {
  i {
    color: #6d48ff !important;
  }
}

.signup-menu-options > :last-child .completed-step,
.invested-customer-content > .completed-step {
  background-color: #000;
  span,
  p {
    color: #ffffff;
  }
  i {
    color: #ffffff !important;
  }
  &:hover {
    background-color: #000000 !important;
  }
}

.signup-menu-options .intermediate-step .right-icon {
  i {
    color: #828282 !important;
  }
}

.non-clickable, .on-hold-step {
  pointer-events: none !important;
  &:hover {
    cursor: inherit !important;
  }
}

.signup-menu-options {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.signup-content .disabled-card {
  background-color: #e9e9e9 !important;
  span {
    color: #828282 !important;
  }
  i {
    color: #828282 !important;
  }
}

.signup-cancel-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
}

.signup-cancel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363;
}

.signup-declarations-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  /* Grises/Gris 01 */
  color: #333333;
}

.signup-form {
  margin: 40px 0;

  input {
    caret-color: black;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .v-messages__message {
    line-height: 160% !important;
    font-weight: 500 !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .file-input.v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #e2e0e0 !important;
  }

  .v-autocomplete__content.v-menu__content,
  .v-autocomplete__content.v-menu__content .v-card {
    border-radius: 10px !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #f20e45;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }

  .signup-input-field + .signup-input-field {
    margin-top: 10px !important;
  }

  .signup-labeled-input-field {
    margin-top: 20px !important;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio .v-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }

  .v-file-input .v-file-input__text--placeholder {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 27px !important;
    color: #333333 !important;
  }

  .v-text-field .v-input__append-inner {
    display: flex;
    align-self: center !important;
    margin: 0;
  }

  .signup-step-button {
    margin-top: 40px;
  }

  .v-input--is-label-active .v-file-input__text {
    display: flex;
    justify-content: right;
    white-space: nowrap;
  }

  .label-row {
    display: flex;
    justify-content: space-between;
  }

  .where-is-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #646363;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .file-inputs > .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 10px 0 21px !important;
  }

  .signup-coupled-inputs
    > .datefield.v-text-field--rounded
    > .v-input__control
    > .v-input__slot {
    input {
      text-align: center;
    }
  }
  .signup-coupled-inputs > :last-child > .v-input__control > .v-input__slot {
    padding: 0 15px;
  }

  .signup-coupled-inputs
    > :not(.v-input--is-focused):not(:first-child):not(:last-child)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-width: 1px 0px 1px 0;
  }
  .input__prepend-inner {
    margin-top: 20px !important;
  }
}

.datefield input::-webkit-outer-spin-button,
.datefield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datefield input[type="number"] {
  -moz-appearance: textfield;
}

p.signup-hint {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  color: #646363;
  margin: 40px 0 0 0 !important;
}

.signup-error-message {
  font-size: 12px;
  line-height: 12px;
  padding: 0 12px;
  color: #ff1744 !important;
  margin-bottom: 8px;
  margin-top: 8px;
}

.signup-button-row {
  display: flex;
  gap: 0 20px;
  margin-top: 40px;
  > * {
    max-width: 200px;
    width: 100%;
  }
}
.signup-button-row .chat-btn::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  span {
    color: #000000 !important;
  }
}

.v-autocomplete__content.v-menu__content,
.v-autocomplete__content.v-menu__content .v-card {
  border-radius: 10px !important;
}

.signup-form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #333333;
  margin-bottom: 10px;
  margin-top: 20px;
}

.signup-autocomplete i.error--text,
.signup-autocomplete i,
.signup-form :not(.prepend-container) > .theme--light.v-icon {
  color: #333333 !important;
}

.signup-custom-menu {
  border: 1px solid #e2e0e0 !important;

  .v-list-item--active {
    background-color: #fafafa !important;
  }
}

.signup-dropdown-item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #333333 !important;
}

.invested-customer-content {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;

  .signup-customer-transactions {
    margin-bottom: 40px;
    .transactions-title > * {
      font-weight: 500;
      font-size: 16px !important;
      line-height: 160%;
      color: #333333;
    }
  }
}

.signup-customer-transactions .transactions-title {
  margin: 0 0 20px 0px !important;
}

.signup-notice-bars > :first-child {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.signup-notice-bars .compass-notice-bar, .signup-notice-bars .compass-pending-account-data {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.signup-notice-bars > :last-child {
  margin-bottom: 40px !important;
}
</style>
